import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice } from '@helpers/utils'
import { Button } from '@components/button'
import { HomeStatement } from '@modules/home/home-statement'
import { SpinInput } from '@components/controls/spin-input'
import { StarShape } from '@components/star-shape'
import ImageGallery from 'react-image-gallery'
import { CarouselNextButton } from '@components/carousel/carousel-next-button'
import { CarouselPreviousButton } from '@components/carousel/carousel-previous-button'
import classnames from 'classnames'
import { ProductDetails } from '@models/products'
import { addBasketItem } from '@store/actions/basket-actions'
import { useAppDispatch } from '@store/hooks'
import { useForm } from 'react-hook-form'
import { useDeviceSize } from '@hooks/use-device-size'
import { useFormRequest } from '@hooks/use-api-request'
import { useNotificationHook } from '@hooks/use-notification-hook'

interface Props {
  product: ProductDetails
}

interface FormInputs {
  quantity: number
}

export const ProductDetailsMainInformation = ({ product }: Props): JSX.Element => {
  const { isDesktop } = useDeviceSize()
  const dispatch = useAppDispatch()
  const [quantity, setQuantity] = React.useState(1)
  const { addSuccessNotification } = useNotificationHook()
  const images = product.images
    .filter(row => row.image)
    .map(row => ({
      thumbnail: row.image?.thumbnail || '',
      original: row.image?.sm || '',
      fullscreen: row.image?.md || '',
    }))

  const { setError, handleSubmit } = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    console.log(payload, quantity)
    await dispatch(addBasketItem({ ...payload, quantity, product }))
    addSuccessNotification('Produkt został dodany do koszyka!')
  }, setError)

  return (
    <div className="d-lg-flex d-block gap-5 mt-3">
      <div className="col-lg-4 col-12">
        <ImageGallery
          additionalClass="product-details__gallery"
          items={images}
          showPlayButton={false}
          lazyLoad={true}
          infinite={false}
          showFullscreenButton={isDesktop}
          renderFullscreenButton={(onClick, isFullscreen) => (
            <i
              className={classnames(
                'font-size-28 product-details__gallery__full-screen',
                isFullscreen ? 'uil-times text-muted' : 'uil-focus text-success',
              )}
              onClick={onClick}
            />
          )}
          renderLeftNav={(onClick, disabled) => (
            <CarouselPreviousButton
              className="product-details__gallery__previous-button"
              disabled={disabled}
              onClick={onClick}
            />
          )}
          renderRightNav={(onClick, disabled) => (
            <CarouselNextButton
              className="product-details__gallery__next-button"
              disabled={disabled}
              onClick={onClick}
            />
          )}
        />
      </div>

      <div className="col-lg-4 col-12 mb-5 d-flex flex-column mt-5 mt-lg-0">
        <div className=" d-flex flex-column align-items-lg-start align-items-center">
          <h1 className="fw-bold font-size-16 my-3">{product.name}</h1>
          <div className="d-flex justify-content-around col-12">
            <div>
              {Array.from(Array(5)).map((el, index) => (
                <StarShape key={index} />
              ))}
            </div>

            <button className="text-warning font-size-13 cursor-pointer btn bg-transparent">
              Opinie ({product.opinions.length})
            </button>
            <button className="btn bg-transparent">
              <IconWithText
                className="gap-2"
                text="Dodaj opinię"
                icon="uil-comment-alt"
                iconClassName="text-warning"
                textClassName="text-warning font-size-13"
              />
            </button>
          </div>
          <div className="my-3" dangerouslySetInnerHTML={{ __html: product.short_description }} />
          <a className="text-success align-self-start">Zobacz pełen opis</a>
        </div>
        <div className="mt-auto">
          <span className="d-block font-size-34 fw-semi-bold">{formatPrice(product.price_brutto)}</span>
          {product.price_brutto_lowest && (
            <span className="d-block font-size-10">
              Najniższa cena w ciągu ostatnich 30 dni: {formatPrice(product.price_brutto_lowest)}
            </span>
          )}
          <form onSubmit={handleSubmit(onSubmit)} className="d-flex mt-3">
            <SpinInput min={1} max={99} value={quantity} onDecrement={setQuantity} onIncrement={setQuantity} />
            <div className="d-flex flex-column ms-3">
              <Button disabled={isLoading} className="border-medium py-2 px-3 font-size-16 w-100 mb-3" type="submit">
                <IconWithText
                  className="gap-2 px-2"
                  text={isLoading ? 'Dodawanie..' : 'Do koszyka'}
                  icon="uil-shopping-cart-alt"
                  textClassName="text-white font-size-16 fw-bold"
                  iconClassName="text-white font-size-22"
                />
              </Button>
              <Button variant="secondary" className="p-2 border-medium w-100">
                <IconWithText
                  className="gap-2 px-2"
                  text="Do ulubionych"
                  icon="uil-heart-alt"
                  textClassName="text-warning font-size-16 fw-bold"
                  iconClassName="text-warning font-size-22"
                />
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-4 col-12">
        <span>Udostępnij</span>
        <div className="py-3">
          <Button variant="secondary" className="border-small me-2">
            <i className="uil-facebook-f font-size-22 text-warning" />
          </Button>
          <Button variant="secondary" className="border-small me-2 ">
            <i className="uil-twitter font-size-22 text-warning" />
          </Button>
          <Button variant="secondary" className="border-small me-2 ">
            <i className="uil-instagram font-size-22 text-warning" />
          </Button>
        </div>
        <HomeStatement
          title="Darmowa Wysyłka"
          message="zamówienie powyżej 100zł"
          icon="delivery-icon.svg"
          className="border-0 mt-5"
        />
        <p className="text-success my-3 fw-semi-bold">{product.status_display}</p>
        <p className="my-3 fw-semi-bold">
          Kup teraz <span className="text-success">otrzymasz w poniedziałek</span>
        </p>
      </div>
    </div>
  )
}
