import * as React from 'react'
import { Input } from '@components/controls/input'
import { PageLayout } from '@components/page-layout'
import { Button } from '@components/button'
import { Select, SelectOption } from '@components/controls/select'
import { FormProvider, useForm } from 'react-hook-form'

interface FormInputs {
  name: string
  phone: string
  mail: string
  question_kind: SelectOption
}

const fakeQuestionKindOptions = [
  { label: 'Temat pytania', value: '1' },
  { label: 'Temat pytania 2', value: '2' },
  { label: 'Temat pytania 3', value: '3' },
]

export const ContactView = (): JSX.Element => {
  const methods = useForm<FormInputs>()

  return (
    <PageLayout>
      <div className="p-3 w-100">
        <p className="text-success fw-bold">Kontakt</p>
        <div className="d-md-flex d-block mx-5">
          <div className="ms-n5 col-12 col-lg-6">
            <div className="d-flex align-items-center gap-4 py-3">
              <i className="uil-envelope text-success font-size-30" />
              <a href="mailto:kontakt@dobrenasiona.pl" className="text-warning">
                kontakt@dobrenasiona.pl
              </a>
            </div>

            <div className="d-flex align-items-center gap-3 py-3">
              <i className="uil-phone text-success font-size-30" />
              <a href="tel:+48602353022">(+48) 602 353 022</a>
            </div>

            <div className="d-flex align-items-center gap-3">
              <img src={require('@assets/images/icons/piorin.svg')} alt="" height={65} width={30} />
              <div>Nr PIORiN: PL-24/66/7059</div>
            </div>
          </div>
          <div className="d-flex ms-n5 align-items-start gap-3 pb-3 py-3">
            <i className="uil-map-marker text-success font-size-30" />
            <div className="col-12">
              <span className="d-block">ul. Główna 4 A</span>
              <span className="d-block">44-144 Nieborowice</span>
              <span>NIP: 969-133-07-32</span>
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <div className="row">
            <div className="col-12 col-md-6">
              <Input name="name" placeholder="Imię i nazwisko" className="col-12" />
              <Input name="phone" placeholder="Nr telefonu" className="col-12" />
              <Input name="email" placeholder="Adres e-mail" className="col-12" />
              <Select
                inputName="question_kind"
                options={fakeQuestionKindOptions}
                className="col-12"
                defaultValue={fakeQuestionKindOptions[0]}
              />
            </div>
            <textarea
              name="message"
              className="border-small d-block bg-light-gray border-green-light p-3 col-12 col-md-6 mt-5 mt-lg-0"
              rows={4}
              placeholder="Treść wiadomości"
            />
          </div>
          <div className="col-6 mt-3">
            <Button className="border-medium py-3 py-lg-2 px-5" type="submit">
              Wyślij
            </Button>
          </div>
        </FormProvider>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1807.3353321284721!2d18.67578487320126!3d50.29484201615293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471130fff6bea62b%3A0xa6363e2094489ca9!2sCz%C4%99stochowska%2012%2C%2044-100%20Gliwice!5e0!3m2!1spl!2spl!4v1653150178942!5m2!1spl!2spl"
          width="100%"
          height="260"
          className="border-medium mt-5"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </PageLayout>
  )
}
