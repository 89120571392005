import * as React from 'react'
import { useAppSelector } from '@store/hooks'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { CartSecondStepForm } from '@modules/shop/cart/cart-second-step/form'
import { commonObjectPut } from '@store/actions/generic-actions'
import { OrderDetails } from '@models/order'
import { RulesCartSecondStepRules } from '@modules/shop/cart/cart-second-step/rules'
import * as R from 'ramda'
import { CartSecondStepPaymentMethod } from '@modules/shop/cart/cart-second-step/payment-method'
import { CartSecondStepDeliveryMethod } from '@modules/shop/cart/cart-second-step/delivery-method'
import { RulesCartSecondStepSummary } from '@modules/shop/cart/cart-second-step/summary'
import { TransportOption } from '@models/shop'
import { useFormRequest } from '@hooks/use-api-request'
import { CartSecondStepOrder } from '@modules/shop/cart/cart-second-step/order'
import { RootState } from '@store/types'

export interface CartSecondStepFormInputs {
  name: string
  phone: string
  email: string
  street: string
  postcode: string
  city: string
  rules: boolean
  rodo: string
  payment_method: 'cash_on_delivery' | 'payu'
  transport_option?: TransportOption
  transport_option_context: object | undefined
}

interface Props {
  basketDetails: OrderDetails
}

export const CartSecondStep = ({ basketDetails }: Props): JSX.Element => {
  const navigate = useNavigate()
  const methods = useForm<CartSecondStepFormInputs>({
    defaultValues: {
      payment_method: 'payu',
      ...R.pick(['name', 'phone', 'email', 'street', 'postcode', 'city'], basketDetails),
    },
  })

  const orderUrl = useAppSelector((state: RootState) => state.websiteState.appData.urls.shop.basket_order)

  const nextStep = (token: string) =>
    navigate(NavigationPath.CartWithParamsAndToken.replace(':step', 'confirmed').replace(':token', token))

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: CartSecondStepFormInputs) => {
    const orderDetails = await commonObjectPut<OrderDetails>(orderUrl, {
      ...payload,
      transport_option: payload.transport_option?.id,
    })

    nextStep(orderDetails.token)
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="p-3 mt-5 d-lg-flex d-block">
        <div className="col-12">
          <CartSecondStepForm />
          <CartSecondStepPaymentMethod />
          <CartSecondStepDeliveryMethod />
          <RulesCartSecondStepSummary basketDetails={basketDetails} />
          <RulesCartSecondStepRules />
          <CartSecondStepOrder isLoading={isLoading} />
        </div>
      </form>
    </FormProvider>
  )
}
